import axios from '@/plugins/axios'

/**
 * Create insertion orders
 */
const createInsertionOrder = async ({
  // eslint-disable-next-line camelcase
  advertiser_id,
  amount,
  // eslint-disable-next-line camelcase
  amount_for_platform,
  // eslint-disable-next-line camelcase
  should_credit_amount_on_platform,
  // eslint-disable-next-line camelcase
  billing_profile,
  // eslint-disable-next-line camelcase
  payment_method,
  // eslint-disable-next-line camelcase
  payment_expiration_date,
  // eslint-disable-next-line camelcase
  nfse_issuance_date,
  // eslint-disable-next-line camelcase
  should_issue_nfse,
  // eslint-disable-next-line camelcase
  nfse_description,
  products,
  // eslint-disable-next-line camelcase
  total_business_amount
}) => {
  try {
    const form = {
      advertiser_id,
      amount: parseInt(amount * 100),
      // eslint-disable-next-line camelcase
      amount_for_platform: parseInt(amount_for_platform * 100),
      should_credit_amount_on_platform,
      billing_profile,
      // eslint-disable-next-line camelcase
      total_business_amount: parseInt(total_business_amount * 100),
      payment_method,
      payment_expiration_date,
      nfse_issuance_date,
      should_issue_nfse,
      nfse_description,
      products
    }
    const response = await axios.post('admin/insertion-orders', form)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Destroy Insertion Order
 */
const destroyInsertionOrder = async id => {
  try {
    const response = await axios.post(`admin/insertion-orders/${id}`, {
      _method: 'DELETE'
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get insertion order
 */
const getInsertionOrder = async (id) => {
  try {
    const response = await axios.get(`admin/insertion-orders/${id}`)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get insertion orders
 */
const getInsertionOrders = async ({
  // eslint-disable-next-line camelcase
  advertiser_id = null,
  // eslint-disable-next-line camelcase
  amount_end = 0,
  // eslint-disable-next-line camelcase
  amount_start = 0,
  // eslint-disable-next-line camelcase
  end_date = null,
  page = 1,
  search = null,
  // eslint-disable-next-line camelcase
  start_date = null,
  status = null
}) => {
  try {
    const params = {
      page: page
    }
    // eslint-disable-next-line camelcase
    if (advertiser_id) params.advertiser_id = advertiser_id
    // eslint-disable-next-line camelcase
    if (amount_start > 0) params.amount_start = parseInt(amount_start * 100)
    // eslint-disable-next-line camelcase
    if (amount_end > 0) params.amount_end = parseInt(amount_end * 100)
    // eslint-disable-next-line camelcase
    if (end_date) params.end_date = end_date
    // eslint-disable-next-line camelcase
    if (start_date) params.start_date = start_date
    if (search) params.search = search
    if (status) params.status = status

    const response = await axios.get('admin/insertion-orders', {
      params: params
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get insertion order
 */
const generatePDF = async (id, {
  // eslint-disable-next-line camelcase
  campaign_name,
  signer,
  // eslint-disable-next-line camelcase
  agency_manager_name,
  // eslint-disable-next-line camelcase
  agency_manager_email,
  // eslint-disable-next-line camelcase
  financial_responsible_name,
  // eslint-disable-next-line camelcase
  financial_responsible_email,
  observation,
  // eslint-disable-next-line camelcase
  column_1_title,
  // eslint-disable-next-line camelcase
  column_2_title,
  // eslint-disable-next-line camelcase
  column_1_desc,
  // eslint-disable-next-line camelcase
  column_2_desc
}) => {
  try {
    const form = {
      campaign_name,
      signer,
      agency_manager_name,
      agency_manager_email,
      financial_responsible_name,
      financial_responsible_email,
      observation,
      column_1_title,
      column_2_title,
      column_1_desc,
      column_2_desc
    }

    const response = await axios.post(`admin/insertion-orders/${id}/generate-pdf`, form)

    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Update campaign
 */
const updateInsertionOrder = async (id, {
  // eslint-disable-next-line camelcase
  advertiser_id,
  amount,
  // eslint-disable-next-line camelcase
  amount_for_platform,
  // eslint-disable-next-line camelcase
  should_credit_amount_on_platform,
  // eslint-disable-next-line camelcase
  billing_profile,
  // eslint-disable-next-line camelcase
  payment_method,
  // eslint-disable-next-line camelcase
  payment_expiration_date,
  // eslint-disable-next-line camelcase
  nfse_issuance_date,
  // eslint-disable-next-line camelcase
  should_issue_nfse,
  // eslint-disable-next-line camelcase
  nfse_description,
  products,
  // eslint-disable-next-line camelcase
  total_business_amount
}) => {
  try {
    const form = {
      advertiser_id,
      amount: parseInt(amount * 100),
      // eslint-disable-next-line camelcase
      amount_for_platform: parseInt(amount_for_platform * 100),
      should_credit_amount_on_platform,
      billing_profile,
      payment_method,
      payment_expiration_date,
      nfse_issuance_date,
      should_issue_nfse,
      nfse_description,
      products,
      // eslint-disable-next-line camelcase
      total_business_amount: parseInt(total_business_amount * 100)
    }
    form._method = 'PUT'
    const response = await axios.post(`admin/insertion-orders/${id}`, form)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get insertion order
 */
const uploadPDF = async (id, {
  file
}) => {
  try {
    const form = new FormData()
    form.append('file', file)

    const response = await axios.post(`admin/insertion-orders/${id}/pdf`, form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Mark insertion order is paid
 */
const markAsPaid = async (id, {
  // eslint-disable-next-line camelcase
  manual_payment_description = null
}) => {
  try {
    const params = {
      // eslint-disable-next-line camelcase
      manual_payment_description,
      _method: 'PUT'
    }
    const response = await axios.post(`admin/insertion-orders/${id}/paid`, params)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  createInsertionOrder,
  destroyInsertionOrder,
  getInsertionOrder,
  getInsertionOrders,
  generatePDF,
  markAsPaid,
  updateInsertionOrder,
  uploadPDF
}
